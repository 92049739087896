import './App.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import Home from 'pages/Home';
import SelectStaff from 'pages/SelectStaff';
import { store } from 'store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const App: React.FC = () => (
  <div className="app">
    <Router>
      <Routes>
        <Route path="/" element={<SelectStaff />} />
        <Route path="/evaluate" element={<Home />} />
        <Route
          key="router-notfound"
          path="*"
          element={(
            <div className="notfound">
              <Heading type="h2" modifiers={['700', 'center']}>
                Đường dẫn không hợp lệ, xin vui lòng thử lại!
              </Heading>
            </div>
          )}
        />
      </Routes>
    </Router>
  </div>
);

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
