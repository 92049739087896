import React, {
  useEffect,
  useState,
  forwardRef,
} from 'react';

import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import mapModifier from 'utils/functions';

interface RatingStarProps {
  label?: string;
  subLabel?: string;
  value?: number;
  onChange?: (star: number) => void;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  isBigTitle?: boolean;
}

const RatingStarRef: React.ForwardRefRenderFunction<
  HTMLDivElement,
  RatingStarProps
> = (
  {
    label,
    subLabel,
    value,
    onChange,
    error,
    required,
    disabled,
    name,
    isBigTitle,
  },
  ref,
) => {
    const [rated, setRated] = useState(0);
    const [hover, setHover] = useState(0);

    useEffect(() => {
      setRated(value || 0);
      setHover(value || 0);
    }, [value]);

    return (
      <div className="o-ratingStar" ref={ref}>
        {label && (
          <label
            className={mapModifier('o-ratingStar_label', isBigTitle ? 'big' : '')}
            htmlFor={name}
          >
            {label}
            {required && <span className="o-ratingStar_label-required">*</span>}
            {subLabel && <span className="o-ratingStar_subLabel">{subLabel}</span>}
          </label>
        )}
        <div className="o-ratingStar_rate">
          {[...Array(5)].map((_, i) => (
            <div
              className="o-ratingStar_item"
              key={`star-${name}-${String(i)}`}
              onClick={() => {
                if (!disabled) {
                  setRated(i + 1);
                  if (onChange) onChange(i + 1);
                }
              }}
              onMouseEnter={() => !disabled && setHover(i + 1)}
              onMouseLeave={() => !disabled && setHover(rated)}
            >
              <Icon iconName={i < hover ? 'starFull' : 'starEmpty'} size="32" />
            </div>
          ))}
        </div>
        {error && (
          <div className="o-ratingStar_error">
            <Text modifiers={['14x20', '500', 'redOrange']}>
              {error}
            </Text>
          </div>
        )}
      </div>
    );
  };

const RatingStar = forwardRef(RatingStarRef);

export default RatingStar;
