import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import {
  useQuery,
  useMutation,
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import EvaluateContainer from './evaluate';
import InfoContainer from './info';

import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import Pulldown from 'components/molecules/Pulldown';
import NotifyModal from 'components/templates/NotifyModal';
import PageLayout from 'components/templates/PageLayout';
import { getPGEvaluatesByCode, postPGEvalutate } from 'services/evaluates';
import { EvaluateFormType } from 'services/evaluates/types';
import { useAppDispatch } from 'store/hooks';
import { clearPgEvaluates } from 'store/pg';
import { schemaRating } from 'utils/schema';

const jobPosition = [
  {
    label: 'Sales Sup/ Sales Rep',
    value: 'sales',
  },
  {
    label: 'Area Sales Manager (ASM)',
    value: 'asm',
  },
  {
    label: 'Regional Commercial Manager (RCM)',
    value: 'regional_commercial',
  },
  {
    label: 'Regional Trade Marketing',
    value: 'regional_trade',
  },
  {
    label: 'Trade Marketing HO',
    value: 'trade_marketing',
  },
  {
    label: 'Khác',
    value: 'other',
  },
];

const Home: React.FC = () => {
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get('staffId') || '';

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState({
    isOpen: false,
    isError: false,
    message: '',
  });
  const method = useForm<EvaluateFormType>({
    mode: 'onSubmit',
    defaultValues: {
      appearance: 0,
      knowledge: 0,
      skill: 0,
      honest: 0,
      customerBehavior: 0,
      outletBehavior: 0,
      note: '',
      isSuggested: false,
      reviewerName: '',
      reviewerPhone: '',
      jobPosition: null,
      jobPositionNote: '',
    },
    resolver: yupResolver(schemaRating),
  });

  const watchJobPosition = method.watch('jobPosition');

  const { data: pgDataRes, isFetching, isError } = useQuery(
    ['getPgDataByCode', staffId],
    () => getPGEvaluatesByCode(staffId || ''),
    {
      enabled: !!staffId,
    }
  );

  const infoData = useMemo(() => {
    if (pgDataRes) {
      return {
        introduce: [
          {
            title: 'Họ và tên',
            description: pgDataRes?.name,
          },
          {
            title: 'Mã nhân viên',
            description: pgDataRes?.employeeCode,
          },
          {
            title: 'Dự án',
            description: pgDataRes?.project?.name,
          },
          {
            title: 'Tỉnh/Thành',
            description: pgDataRes?.province?.name,
          },
        ],
        imageList: pgDataRes?.profiles?.map((el) => ({ url: el })).slice(0, 3) || [],
        dataTable: pgDataRes?.workings?.map((item) => ({
          date: `${dayjs(item.timeWorkStart).format('DD/MM')} - ${dayjs(item.timeWorkEnd).format('DD/MM')}`,
          outletName: item.outletName,
          shiftAmount: item.quantity,
          saleAverage: item.averageSales || 0,
        })) || [],
      };
    }
    return undefined;
  }, [pgDataRes]);

  const handleClose = () => {
    setModal({
      ...modal,
      isOpen: false,
      message: '',
    });
    if (!modal.isError) {
      dispatch(clearPgEvaluates());
    }
  };

  const { mutate, isLoading } = useMutation(postPGEvalutate, {
    onSuccess: () => {
      setModal({
        isOpen: true,
        isError: false,
        message: 'Gửi thành công',
      });
      method.reset();
    },
    onError: () => {
      setModal({
        isOpen: true,
        isError: true,
        message: 'Đã có lỗi xảy ra!',
      });
    }
  });

  const handleSubmit = (data: EvaluateFormType) => {
    mutate({
      phone: data.reviewerPhone,
      name: data.reviewerName,
      employee_code: staffId || '',
      appearance: data.appearance,
      product_knowledge: data.knowledge,
      sale_skill: data.skill,
      honesty_in_work: data.honest,
      behavior_toward_customer: data.customerBehavior,
      behavior_toward_outlet: data.outletBehavior,
      comment: data.note,
      used_another_project: data.isSuggested,
      position_evaluate: data?.jobPosition?.value || '',
      note: data.jobPosition?.value === 'other' ? data.jobPositionNote : '',
    });
  };

  useEffect(() => {
    if (!staffId) {
      navigate('/');
    }
  }, [staffId, navigate]);

  useEffect(() => {
    if (watchJobPosition?.value !== 'other' && method?.formState?.errors?.jobPositionNote) {
      method.clearErrors('jobPositionNote');
    }
  }, [method, watchJobPosition]);

  if (isError) {
    return (
      <div className="notfound">
        <Heading type="h2" modifiers={['700', 'center']}>
          Mã nhân viên không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <PageLayout headerProps={{ title: 'ĐÁNH GIÁ SP' }}>
      <div className="p-home">
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(handleSubmit)}>
            <div className="p-home_panel">
              <div className="p-home_title">
                <Text modifiers={['16x24', 'gunmetal', '600']}>
                  Thông tin người đánh giá SP
                </Text>
              </div>
              <Controller
                name="reviewerName"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    type="text"
                    error={fieldState?.error?.message}
                    label="Họ và tên"
                    placeholder="Nhập họ và tên"
                    required
                    id="reviewerName"
                  />
                )}
              />
              <div className="u-mt-16">
                <Controller
                  name="reviewerPhone"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      type="tel"
                      error={fieldState?.error?.message}
                      label="Số điện thoại"
                      placeholder="Nhập số điện thoại"
                      id="reviewerPhone"
                      required
                    />
                  )}
                />
              </div>
              <div className="u-mt-16">
                <Controller
                  name="jobPosition"
                  render={({ field, fieldState }) => (
                    <Pulldown
                      label="Vị trí công việc"
                      error={fieldState?.error?.message}
                      placeholder="Chọn vị trí công việc"
                      value={field.value}
                      options={jobPosition}
                      handleSelect={field.onChange}
                      required
                    />
                  )}
                />
              </div>
              <div className={`u-mt-16 ${watchJobPosition?.value === 'other' ? '' : 'd-none'}`}>
                <Controller
                  name="jobPositionNote"
                  render={({ field, fieldState }) => (
                    <Input
                      {...field}
                      type="text"
                      error={fieldState?.error?.message}
                      label="Vui lòng ghi rõ vị trí công việc"
                      placeholder="Nhập vị trí công việc"
                      id="jobPositionNote"
                      required
                    />
                  )}
                />
              </div>
            </div>
            {(isFetching) && (
              <div className="p-home_loading">
                <Loading isShow variant="fullScreen" />
              </div>
            )}
            <InfoContainer infoData={infoData} />
            <EvaluateContainer isLoading={isLoading} method={method} />
          </form>
        </FormProvider>
      </div>
      <NotifyModal
        isError={modal.isError}
        isOpen={modal.isOpen}
        title={modal.message}
        submitText="Đóng"
        handleSubmit={handleClose}
        smallTitle
      />
    </PageLayout>
  );
};

export default Home;
