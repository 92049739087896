import React, { useEffect, useRef } from 'react';
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form';

import Button from 'components/atoms/Button';
import Radio from 'components/atoms/Radio';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/Textarea';
import RatingStar from 'components/molecules/RatingStar';
import SectionTitle from 'components/organisms/SectionTitle';
import { EvaluateFormType } from 'services/evaluates/types';
import { scrollDownNextSection } from 'utils/functions';

interface EvaluateProps {
  isLoading?: boolean;
  method: UseFormReturn<EvaluateFormType>;
}

const EvaluateContainer: React.FC<EvaluateProps> = ({
  isLoading,
  method,
}) => {
  const starRef = useRef<HTMLDivElement>(null);

  const watchAppearance = method.watch('appearance');
  const watchKnowledge = method.watch('knowledge');
  const watchSkill = method.watch('skill');
  const watchHonest = method.watch('honest');
  const watchCustomerBehavior = method.watch('customerBehavior');
  const watchOutletBehavior = method.watch('outletBehavior');

  const total = (
    watchAppearance
    + watchKnowledge
    + watchSkill
    + watchHonest
    + watchCustomerBehavior
    + watchOutletBehavior
  ) / 6;

  const errorList = method.formState.errors;

  useEffect(() => {
    if (Object.keys(errorList).length > 0
      && Object.keys(errorList).length <= 6
      && starRef?.current
    ) {
      if (starRef?.current) {
        scrollDownNextSection(starRef);
      }
    }
  }, [errorList, starRef]);

  return (
    <div className="p-home_evaluate">
      <SectionTitle title="Đánh giá" />
      <div className="p-home_evaluate-form">
        <div className="p-home_starList" ref={starRef}>
          <div className="u-mt-16">
            <Controller
              name="appearance"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="appearance"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Ngoại hình phù hợp với công việc"
                  required
                />
              )}
            />
          </div>
          <div className="u-mt-16">
            <Controller
              name="knowledge"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="knowledge"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Kiến thức sản phẩm"
                  required
                />
              )}
            />
          </div>
          <div className="u-mt-16">
            <Controller
              name="skill"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="skill"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Kỹ năng bán hàng"
                  required
                  subLabel="(Thuyết phục, giao tiếp, thương lượng)"
                />
              )}
            />
          </div>
          <div className="u-mt-16 p-home_divider" />
          <div className="u-mt-16">
            <Controller
              name="honest"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="honest"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Trung thực trong công việc"
                  required
                />
              )}
            />
          </div>
          <div className="u-mt-16">
            <Controller
              name="customerBehavior"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="customerBehavior"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Hành xử đối với khách hàng"
                  required
                />
              )}
            />
          </div>
          <div className="u-mt-16">
            <Controller
              name="outletBehavior"
              render={({ field, fieldState: { error } }) => (
                <RatingStar
                  {...field}
                  name="outletBehavior"
                  value={field?.value}
                  onChange={field?.onChange}
                  error={error?.message}
                  label="Hành xử đối với Outlet"
                  required
                />
              )}
            />
          </div>
        </div>
        <div className="u-mt-16 p-home_divider" />
        <div className="u-mt-16 p-home_total">
          <Text modifiers={['16x24', '600', 'jet']}>
            Tổng hợp đánh giá:
          </Text>
          <div className="p-home_total-score">
            <Text modifiers={['14x20', '600', 'gunmetal2']}>
              {total === 0 ? total : total.toFixed(1)}
            </Text>
          </div>
        </div>
        <div className="u-mt-16">
          <Controller
            name="note"
            render={({ field, fieldState: { error } }) => (
              <TextArea
                {...field}
                rows={4}
                value={field?.value}
                placeholder="Viết nhận xét về kết quả thực hiện"
                label="Nhận xét"
                id="text"
                handleOnchange={field?.onChange}
                error={error?.message}
              />
            )}
          />
        </div>
        <div className="u-mt-16">
          <Controller
            name="isSuggested"
            render={({ field, fieldState }) => (
              <>
                <div>
                  <Text type="span" modifiers={['16x24', 'jet', '600']}>Đề nghị sử dụng cho các dự án khác</Text>
                </div>
                <div className="p-home_radioGroup">
                  <div className="p-home_radioGroup-item">
                    <Radio onChange={() => field.onChange(true)} checked={field.value} label="Có" />
                  </div>
                  <div className="p-home_radioGroup-item">
                    <Radio onChange={() => field.onChange(false)} checked={!field.value} label="Không" />
                  </div>
                </div>
                {fieldState?.error?.message && (
                  <div className="u-mt-8">
                    <Text
                      modifiers={['14x20', 'redOrange', '400']}
                    >
                      {fieldState?.error?.message}
                    </Text>
                  </div>
                )}
              </>
            )}
          />
        </div>
        <div className="p-home_evaluate-button">
          <Button
            type="submit"
            modifiers={['primary']}
            disabled={isLoading || !!Object.keys(errorList).length}
            loading={isLoading}
          >
            <Text modifiers={['white', '16x24', '600', 'center']}>
              Nhận xét
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EvaluateContainer;
