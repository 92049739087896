import React, { useState } from 'react';

import Image from 'components/atoms/Image';
import Text from 'components/atoms/Text';
import InfoCard from 'components/organisms/InfoCard';
import CustomModal from 'components/organisms/Modal';
import Table from 'components/organisms/Table';

interface InfoProps {
  infoData?: {
    introduce?: {
      title: string;
      description: string;
    }[],
    imageList?: {
      url: string;
    }[],
    dataTable?: {
      date: string;
      outletName: string;
      shiftAmount: number;
      saleAverage: number;
    }[],
  }
}

const dataHeaderDummy = [
  { field: 'date', headerName: 'Ngày làm việc' },
  { field: 'outletName', headerName: 'Tên Outlet' },
  { field: 'shiftAmount', headerName: 'Số ca đã làm' },
  { field: 'saleAverage', headerName: 'Số bán TB' },
];

const InfoContainer: React.FC<InfoProps> = ({
  infoData,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  if (!infoData) return null;
  return (
    <div className="p-home_info">
      <div className="p-home_info-cardList">
        <InfoCard dataInfoCard={infoData?.introduce} />
      </div>
      <div className="p-home_info-imageWrapper">
        <Text modifiers={['500', '14x20', 'smokyBlack']}>Ảnh nhân viên</Text>
        {infoData?.imageList && infoData?.imageList?.length > 0 && (
          <div className="p-home_info-imageList">
            {infoData?.imageList.map((item, idx) => (
              <div className="p-home_info-imageItem" key={`staff-avatar-${String(idx)}`}>
                <Image
                  imgSrc={item.url}
                  ratio="1x1"
                  alt={`staff-avatar-${String(idx)}`}
                  handleClick={() => setImageUrl(item.url)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="p-home_info-table">
        <Table headerTable={dataHeaderDummy} dataTable={infoData?.dataTable} />
      </div>
      <CustomModal
        isOpen={!!imageUrl}
        modifiers={['width-336']}
        handleClose={() => setImageUrl('')}
        showIconClose
      >
        <Image imgSrc={imageUrl} alt="staff-avatar" size="cover" ratio="480x640" />
      </CustomModal>
    </div>
  );
};
export default InfoContainer;
