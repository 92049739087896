import {
  PGEvaluatesParams,
  PGEvaluatesDataTypes,
  PGDataTypes,
  EvaluatesPostData,
} from './types';

import axiosInstance from 'services/common/instance';

export const getPGEvaluates = async (params: PGEvaluatesParams)
  : Promise<PGEvaluatesDataTypes[]> => {
  const res = await axiosInstance.get('pg-evaluates', { params });
  return res.data.data;
};

export const getPGEvaluatesByCode = async (code: string)
  : Promise<PGDataTypes> => {
  const res = await axiosInstance.get(`pg-evaluates/${code}`);
  return res.data.data;
};

export const postPGEvalutate = async (params: EvaluatesPostData): Promise<void> => {
  await axiosInstance.post('pg-evaluates', params);
};
