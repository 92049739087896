import React, {
  forwardRef
} from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  id: string;
  label?: string;
  subLabel?: string;
  error?: string;
}

const InputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
  type,
  id,
  label,
  subLabel,
  error,
  ...props
}, ref) => (
  <div
    className={mapModifiers(
      'a-input',
      type,
      error && 'error',
    )}
  >
    {label && (
      <div className="a-input_label">
        <label htmlFor={id}>
          <Text
            modifiers={['14x20', '600', 'gunmetal']}
          >
            {label}
          </Text>
        </label>
        {props.required && <span className="a-input_label-required">*</span>}
      </div>
    )}
    {subLabel && (
      <div className="a-input_subLabel">
        <label htmlFor={id}>
          <Text
            modifiers={['14x20', '500', 'gunmetal']}
          >
            {subLabel}
          </Text>
        </label>
      </div>
    )}
    <div className="a-input_wrap">
      <input
        {...props}
        className="a-input_input"
        type={type}
        ref={ref}
        placeholder={props.placeholder}
      />
    </div>
    {error && (
      <div className={mapModifiers('a-input_messageError')}>
        <Text
          modifiers={['14x20', 'redOrange', '400']}
        >
          {error}
        </Text>
      </div>
    )}
  </div>
);

const Input = forwardRef(InputRef);

Input.defaultProps = {
  children: undefined,
};

export default Input;
