import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PGEvaluatesDataTypes {
  staffId: string;
}

interface SystemState {
  pgEvaluates?: PGEvaluatesDataTypes;
}

const initialState: SystemState = {
};

export const exampleSlice = createSlice({
  name: 'exampleReducer',
  initialState,
  reducers: {
    setPgEvaluates($state, action: PayloadAction<PGEvaluatesDataTypes>) {
      $state.pgEvaluates = action.payload;
    },
    clearPgEvaluates($state) {
      $state.pgEvaluates = undefined;
    },
  },
  extraReducers() {
  },
});

export const {
  setPgEvaluates,
  clearPgEvaluates,
} = exampleSlice.actions;

export default exampleSlice.reducer;
