import * as yup from 'yup';

import { OptionType } from 'components/molecules/Pulldown';

export const phoneRegExp = /(0[3|5|7|8|9])+([0-9]{8})\b/;

export const schemaRating = yup.object({
  appearance: yup.number().required('Vui lòng đánh giá ngoại hình phù hợp với công việc').min(1, 'Tối thiểu đánh giá 1 sao'),
  knowledge: yup.number().required('Vui lòng đánh giá kiến thức sản phẩm').min(1, 'Tối thiểu đánh giá 1 sao'),
  skill: yup.number().required('Vui lòng đánh giá kỹ năng bán hàng').min(1, 'Tối thiểu đánh giá 1 sao'),
  honest: yup.number().required('Vui lòng đánh giá trung thực trong công việc').min(1, 'Tối thiểu đánh giá 1 sao'),
  customerBehavior: yup.number().required('Vui lòng đánh giá hành xử đối với khách hàng').min(1, 'Tối thiểu đánh giá 1 sao'),
  outletBehavior: yup.number().required('Vui lòng đánh giá hành xử đối với Outlet').min(1, 'Tối thiểu đánh giá 1 sao'),
  reviewerName: yup.string().required('Tên người đánh giá là bắt buộc'),
  reviewerPhone: yup.string().required('Số điện thoại người đánh giá là bắt buộc').matches(phoneRegExp, 'Không đúng định dạng'),
  jobPosition: yup.object().nullable().required('Vị trí công việc là bắt buộc'),
  jobPositionNote: yup.string()
    .when(['jobPosition'], {
      is: (jobPosition: OptionType) => jobPosition && jobPosition?.value === 'other',
      then: yup.string().required('Vị trí chi tiết công việc là bắt buộc')
    }),
});

export const schemaSelectStaff = yup.object({
  staffId: yup.string().required('Mã nhân viên là bắt buộc'),
});
