import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState } from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import {
  useQuery,
} from 'react-query';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import PageLayout from 'components/templates/PageLayout';
import useDebounce from 'hooks/useDebounce';
import { getPGEvaluates } from 'services/evaluates';
import { schemaSelectStaff } from 'utils/schema';

type SelectStaffFormData = {
  staffId: string;
};

type SearchItem = {
  name: string;
  id: string;
  handleClick?: () => void;
};

const SearchItemComponent: React.FC<SearchItem> = ({
  name,
  id,
  handleClick,
}) => (
  <div className="p-selectStaff_searchList-item" onClick={handleClick}>
    <Text modifiers={['14x20', '500', 'smokyBlack']}>
      {name}
    </Text>
    <div className="u-mt-4">
      <Text modifiers={['14x20', '500', 'stormcloud']}>
        {`Mã nhân viên: ${id}`}
      </Text>
    </div>
  </div>
);

const SelectStaff: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const searchDebounce = useDebounce(search, 500);
  const method = useForm<SelectStaffFormData>({
    mode: 'onSubmit',
    defaultValues: {
      staffId: '',
    },
    resolver: yupResolver(schemaSelectStaff),
  });

  const { data: searchRes, isFetching } = useQuery(
    ['getSearchPgCode', searchDebounce],
    () => getPGEvaluates({
      employee_code: searchDebounce,
    }),
    {
      enabled: !!searchDebounce,
    }
  );

  const searchList = useMemo(() => {
    if (searchRes) {
      return searchRes.map((item) => ({
        name: item.name,
        id: item.employeeCode,
      }));
    }
    return [];
  }, [searchRes]);

  const handleSubmit = (data: SelectStaffFormData) => {
    navigate(`/evaluate?staffId=${data.staffId}`);
  };

  return (
    <PageLayout headerProps={{ title: 'ĐÁNH GIÁ SP', type: 'secondary' }}>
      <div className="p-selectStaff">
        <FormProvider {...method}>
          <form noValidate onSubmit={(e) => { e.preventDefault(); }}>
            <div className="p-selectStaff_panel u-mt-8">
              <div className="p-selectStaff_title">
                <Text modifiers={['16x24', 'gunmetal', '600']}>
                  Thông tin SP
                </Text>
              </div>
              <Controller
                name="staffId"
                render={({ field, fieldState }) => (
                  <Input
                    {...field}
                    type="text"
                    onChange={(e) => {
                      field.onChange(e);
                      setSearch(e.target.value);
                    }}
                    error={fieldState?.error?.message}
                    label="Mã nhân viên"
                    placeholder="Nhập mã nhân viên"
                    required
                    id="staffId"
                  />
                )}
              />
            </div>
            {
              (isFetching) && (
                <div className="loadingWrap u-mt-16">
                  <Icon iconName="loading" size="32" />
                </div>
              )
            }
            {searchList?.length > 0 && (
              <div className="p-selectStaff_searchList">
                <div className="p-selectStaff_selectText">
                  <Text modifiers={['14x20', 'redOrange', '400']}>
                    Vui lòng chọn SP để đánh giá
                  </Text>
                </div>
                <div className="p-selectStaff_selectList u-mt-8">
                  {searchList.map((item, idx) => (
                    <SearchItemComponent
                      name={item.name}
                      id={item.id}
                      key={`staff-${String(idx)}`}
                      handleClick={() => {
                        method.setValue('staffId', item.id);
                        method.handleSubmit(handleSubmit)();
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </form>
        </FormProvider>
      </div>
    </PageLayout>
  );
};
export default SelectStaff;
