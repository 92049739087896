import React from 'react';

import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

export interface HeaderProps {
  title?: string;
  type?: 'primary' | 'secondary';
}

const Header: React.FC<HeaderProps> = ({
  title,
  type,
}) => (
  <div className={mapModifiers('o-header', type)}>
    <Text
      modifiers={[
        '16x24',
        '600',
        type === 'secondary' ? 'gunmetal' : 'white',
      ]}
    >
      {title}
    </Text>
  </div>
);

export default Header;
