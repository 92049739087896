import React from 'react';

import Text from 'components/atoms/Text';

interface InfoCardProps {
  dataInfoCard?: {
    title?: string,
    description?: string,
  }[]
}

const InfoCard: React.FC<InfoCardProps> = ({
  dataInfoCard
}) => (
  <div className="o-infoCard">
    {
      dataInfoCard?.map((item, idx) => (
        <div key={`o-infoCard-${idx.toString()}`} className="o-infoCard_info">
          <Text type="span" modifiers={['14x20', '500', 'smokyBlack']}>{item.title}</Text>
          {' '}
          <Text type="span" modifiers={['14x20', '500', 'stormcloud']} content={item.description} />
        </div>
      ))
    }
  </div>
);

export default InfoCard;
